import React from 'react';
import styled from 'styled-components';

const List = ({renderItem, data, ...otherProps}) => {
	return ( 
		<Wrapper {...otherProps}>
			{
				data.map((item, key) => ( renderItem({...item, key}) ) )
			}
		</Wrapper>
	 );
}
 
export default List;

const Wrapper = styled.div`
display: flex;
flex-direction: column;
width: 90%;
align-items: center;

@media(min-width: 800px){
	width: 90%;
	flex-direction: row;
	justify-content: space-between;
}
`;