import React from 'react';
import styled from 'styled-components';
import { Colors } from '../../utils/colors';

const CustomSection = ({children, title, ...otherProps}) => {
	return ( 
		<SectionContainer {...otherProps}>
			<Title>{title}</Title>
			
			{children}
	
		</SectionContainer>
	 );
}
 
export default CustomSection;

const SectionContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
`;

const Title = styled.h1`
	font-family: "Circular Std Bold", Lato, sans-serif;
	font-weight: 600;
	font-size: 25px;
	color: ${Colors.tertiary};
	margin-bottom: 10px;

	@media(min-width: 800px){
		font-size: 50px;
		margin-bottom: 20px;
	}
`;