import React from 'react';
import styled from 'styled-components';
import { Colors } from '../utils/colors';

const CircularCard = (props) => {
	return ( 
		<Wrapper key={props.key}>
			<Img src={props.icon}/>
			<Title>{props.title}</Title>
		</Wrapper>
	 );
}
 
export default CircularCard;

const Wrapper = styled.div`
background-color: ${Colors.parlor_light_purple};
width: 250px;
height: 250px;
border: none;
border-radius: 50%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
margin-top: 20px;

@media(min-width: 1200px){
	margin-top: 0;
	width:300px;
	height: 300px;
}
`;

const Img = styled.img`
height: 50px;
margin-bottom: 0
`;

const Title = styled.p`
font-family: "Circular Std Book", Lato, sans-serif;
font-size: 20px;
line-height: 35px;
margin-top: 20px;
margin-bottom: 0;
`;