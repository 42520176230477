import React from 'react';
import styled from 'styled-components';
import { Colors } from '../utils/colors';

const Card = (props) => {
	return ( 
		<Container key={props.key}>
			<Img src={props.icon}/>
			<Title>{props.title}</Title>
			{
			props.subtitle ? 
			<Subtitle>{props.subtitle}</Subtitle>
				:
				''
			}
		</Container>
	 );
}
 
export default Card;

const Container = styled.div`
display: flex;
flex-direction: column;
align-items: center;
width: 100%;
background-color: white;
box-shadow: 1px 1px 4px rgba(0,0,0,0.16);
margin-top: 20px;
padding-top: 30px;
padding-bottom: 30px;
height: 200px;

@media(min-width: 800px){
	width: 33%;
	margin-top: 0px;
}

@media(min-width: 1200px){
	width: 400px;
	margin-top: 0;
}
`;

const Img = styled.img`
height: 50px;
margin-bottom: 0;
margin-top: 20px;
`;

const Title = styled.p`
font-family: "Circular Std Book", Lato, sans-serif;
font-size: 20px;
margin-top: 20px;
margin-bottom: 0px;
color: ${Colors.tertiary};
width: 270px;
text-align: center;

@media(min-width: 800px){
	font-size: 14px;
	width: 200px;
	margin-bottom: 0px;
}

@media(min-width: 1200px){
	font-size: 20px;
	width: 270px;
}
`;

const Subtitle = styled.p`
font-family: "Circular Std Book", Lato, sans-serif;
font-size: 14px;
margin-top: 0px;
margin-bottom: 0;
color: ${Colors.parlor_grey}

@media(min-width: 1000px){
	font-size: 18px;
}
`;