import React from "react"
import styled from "styled-components";
import Layout from "../components/layout"
import SEO from "../components/seo"
import CustomButton from "../components/custom-button/custom-button.component"
import heroImg from '../images/HeroImg.png';
import { Colors } from "../utils/colors"
import CustomSection from "../components/section/custom-section.component";
import Card from "../components/card.component";
import List from "../components/list/list.component";
import CircularCard from '../components/circular-card.component';
import 'firebase/analytics'
import { navigate } from "gatsby";

const IndexPage = () => {
	return (

		<Layout>
			<SEO title="DParlour" />
			<Hero>
				<Container>
					<HeroCaption>
						<HeroTitle>Accept Payments Easily<span style={{'display': 'block'}}>As Simple as Sharing a Link</span></HeroTitle>
						<HeroSubtitle>Freelancing is hard getting paid shouldn't be</HeroSubtitle>
					</HeroCaption>

					<InviteButton background={Colors.secondary} width='150px' color='white' onClick={() => navigate("request-invite") }>
						Get Your Invitation
					</InviteButton>
				</Container>

				<ImageContainer>
					<Img src={heroImg}/>
				</ImageContainer>
			</Hero>

			<HowItWorksSection id="howitworks" title='How it Works'>
				<List
					data={HowItWorksData}
					renderItem={Card}
					style={{marginTop: '20px'}}
				/>
			</HowItWorksSection>
			
			<FeaturesSection id="features" title="Features">
				<FeaturesList 
				data={FeaturesData}
				renderItem={CircularCard}
				/>
			</FeaturesSection>
			
			<PerksSection id="perks" title="Perks">
				<PerksSubtitle>We know freelancing is hard so we created a platform where you get employee benefits while still being your own boss</PerksSubtitle>

				<PerksWrapper>
					<PerkTextList>
						<PerkText>Monthly discounts on ride share services.</PerkText>
						<PerkText>Discounts at select restaurants.</PerkText>
						<PerkText>Affordable Travel Packages.</PerkText>
						<PerkText>Discounted party tickets.</PerkText>
					</PerkTextList>
					<PerkIcon>
						<PerkImg src={require('../images/shopingIcon.png')} />
					</PerkIcon>
				</PerksWrapper>

			</PerksSection>

			<RequestInviteButton 
				width='200px' 
				background={Colors.primary} 
				color='white'
				onClick={() => navigate("request-invite")}
				>
				Request your invite now! &rarr;
			</RequestInviteButton>

		</Layout>
	)
}

export default IndexPage

const HowItWorksData = [
	{
		title: "Sign Up",
		icon: require('../images/SignUpIcon.png')
	},
	{
		title: "Generate your unique link,",
		subtitle: "dparlour.me/username",
		icon: require('../images/ChainIcon.png')
	},
	{
		title: "Share your url with clients to get paid",
		icon: require('../images/ShareLinkIcon.png')
	}
];

const FeaturesData = [
	{
		title: "Dashboard",
		icon: require('../images/DashboardIcon.png')
	},
	{
		title: "Sales Report",
		icon: require('../images/SalesReportIcon.png')
	},
	{
		title: "Direct Bank Transfer",
		icon: require('../images/BankIcon.png')
	}
]

const Hero = styled.div`
display: flex;
position: relative;
height: 320px;

@media(min-width: 800px){
	height: 500px
}
`;

const Container = styled.div`
	flex: 2;
	position: relative;
	z-index: 1;
	box-sizing: border-box;

	@media(min-width: 800px){
		align-self: center;
	}
`;

const ImageContainer = styled.div`
position: absolute;
flex: 1;
right: 0;
bottom: 0;
height: 160px;
transform: translateY(-10%);

	@media(min-width: 800px){
		height: 400px;
		transform: translateY(-10%);
	}

	@media(min-width: 1000px){
		height: 500px;
		transform: translateY(0);
	}

	@media(min-width: 1000px){
		height: 500px;
		transform: translateY(0);
	}
`;

const InviteButton = styled(CustomButton)`
margin-top: 40px;
outline: none;

@media(min-width: 1000px){
	height: 60px;
	font-size: 25px;
	width: 300px;
}
`;

const Img = styled.img`
height: 100%;
`;

const HeroCaption = styled.div`
`;

const HeroTitle = styled.h1`
	font-family: "Circular Std Bold", Lato, sans-serif;
	font-size: 25px;
	color: ${Colors.tertiary};
	margin-top: 40px;
	margin-bottom: 10px;

	@media(min-width: 900px){
		font-size: 40px;
	}

	@media(min-width: 1200px){
		font-size: 55px;
		line-height: 70px;
	}
`;

const HeroSubtitle = styled.h5`
	font-family: Lato, sans-serif;
	font-size: 12px;
	color: ${Colors.parlor_grey};
	margin-top: 0;
	font-weight: 500;

	@media(min-width: 900px){
		font-size: 20px;
		line-height: 20px;
	}
`;


const HowItWorksSection = styled(CustomSection)`
margin-top: 30px;
@media(min-width: 1200px){
	margin-top: 100px;
}
`

const FeaturesSection = styled(CustomSection)`
margin-top: 100px;
`;


const FeaturesList = styled(List)`
width: 90%;
justify-content: space-between;
margin-top: 30px;
`;

const PerksSection = styled(CustomSection)`
margin-top: 120px;
`;

const PerksSubtitle = styled.p`
margin-top: 0px;
font-family: "Circular Std Book", Lato, sans-serif;
font-size: 18px;
opacity: 0.6;
color: ${Colors.tertiary};
text-align: center;
width: 70%;

@media(min-width: 800px){
	font-size: 28px;
}
`

const PerksWrapper = styled.div`
width: 100%;
display: flex;
flex-direction: column;
margin-top: 50px;

@media(min-width: 1200px){
	flex-direction: row;
}
`;

const PerkTextList = styled.ul`
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
box-sizing: border-box;
padding: 20px;
order: 2;

@media(min-width: 1200px){
	width: 60%;
	order: 1;
}
`;

const PerkIcon = styled.div`
width: 100%;
order: 1;
@media(min-width: 1200px){
	width: 60%;
	order: 2;
}
`;

const PerkImg = styled.img`
width: 100%;
margin-bottom: 0;

`;

const PerkText = styled.li`
font-family: "Circular Std Book", Lato, sans-serif;
font-size: 18px;
color: ${Colors.tertiary};
margin-bottom: 10px;

@media(min-width){
	font-size: 28px;
}
`

const RequestInviteButton = styled(CustomButton)`
display: block;
margin-left: auto;
margin-right: auto;
margin-top: 50px;
margin-bottom: 40px;
width: 300px;
outline: none;

@media(min-width: 1200px){
	width: 500px;
}
`;

