import React from 'react';
import styled from 'styled-components';
import { Colors } from '../../utils/colors';

const CustomButton = ({children, ...otherProps}) => {
return (
   <ButtonWrapper {...otherProps}>
       {children}
   </ButtonWrapper>
);
}

export default CustomButton

const ButtonWrapper = styled.button`
width:${props => props.width || '190px'};
height: 40px;
border-radius: 13px;
border: none;
background: ${props => props.background || 'transparent'};
font-size: 14px;
color: ${props => props.color || Colors.tertiary};
font-family: "Circular Std Book", "Lato", "sans-serif";
font-weight: 500;
outline: none;
cursor: pointer;
`;
